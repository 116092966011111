.App {
  margin: 0;
  padding: 0;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  transition: .4s;
}

.App.pro {
  background: #131415;
  transition: .4s;
}

.header {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  color: #222;
  max-width: 1280px;
  margin: 0 auto;
  min-height: 140px;
  /* flex-direction: column; */
}

@media only screen and (max-width: 1045px) {
  .header {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .left {
    /* min-height: 100px; */
  }
}

.stats {
  display: flex;
  width: 80%;
  height: 100%;
  align-items: center;
  margin: 10px 0;
}

.statbox {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fafafa;
  height: 60px;
  margin: 0 2px;
  color: #384f6d;
  flex: 1;
  font-size: 22px;
  font-weight: bold;
}

.statbox:nth-of-type(even) {
  background: #f1f1f1;
}

.statbox .label {
  font-size: 12px;
  font-weight: 400;
  order: 2;
}

.left {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 40%;
  height: 100%;
  text-decoration: none;
  font-size: 20px;
  min-width: 300px;
  font-family: 'Patua One', cursive;
  flex:1;
}

.left a {
  text-decoration: none;
}
.left a h1{
  color: #384f6d;
  margin: 10px 0;
  font-weight: 400;
  border-bottom: 6px dotted #00b4c8;
}

.searchForm,
.lastForm,
.historyForm {
  display: flex;
  justify-content: center;
  position: relative;
}

.searchNotification {
  position: absolute;
  right:0;
  left:0;
  bottom:1px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .4s;
}

.searchForm.note input {
  padding-bottom: 10px;
  outline: 2px solid #384f6d;
}

.searchForm input,
.lastForm .lastInput,
.historyForm .historyInput {
  width: 70%;
  min-width: 300px;
  min-height: 40px;
  padding: 0 5px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 15px;
  font-weight: 400 !important;
}
.searchForm input:focus-visible {
  border: 1px solid transparent;
  outline: 2px solid #384f6d;
  outline-offset: 1px;
  transition: .2s;
}

.historyForm .historyInput,
.lastForm .lastInput {
  display: flex;
  align-items: center;
  border:1px solid transparent;
  min-height: 26px !important;
  margin-left: 50px;
  position: relative;
}
.historyForm::before,
.lastForm::before {
  content: "";
  position: absolute;
  border-radius: 20px;
  left:10px;
  top:12px;
  height: 12px;
  width: 12px;
  background: #00b4c8;
}
.historyForm::before {
  background: #ccc;
}
.last, .history {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lastForm {
  max-width: 312px;
  background: #fff;
  margin: 4px 0 0;
  height: 36px;
  border-radius: 100px;
  border: 1px solid #f2f2f2;
}
.historyForm {
  max-width: 312px;
  background: white;
  margin: 4px 0 0;
  background: #f7f7f7;
  height: 36px;
  border-radius: 100px;
  border: 1px solid #f2f2f2;
}
.lastForm button,
.historyForm button {
  background-color: transparent;
  border: 0;
  color: #707070;
  position: absolute;
  top:0;
  bottom:0;
  right:28px;
  line-height: 100%;

}
.lastForm button:hover,
.historyForm button:hover {
  color:#000;
  cursor: pointer;
}




.center {
  height: 100%;
  flex: 1;
  margin: 10px 0;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.chart,
.filtered {
  width: 100%;
  color: #222;
  display: flex;
  justify-content: center;
  align-items: center;
}

.newsTable {
  margin: 20px 0;
}

.right {
  display: flex;
  position: relative;
  max-width: 100%;
  height: 100%;
  flex-direction: column;
  min-width: 300px;
  flex: 1;
}

.noResultsText, .totalResults, .badSearch {
  font-size: 13px;
  color: #ccc;
  display: flex;
  min-height: 20px;
}
.totalResults {
  color: #384f6d;
}
.badSearch {
  color: #8f3434;
}

.rowTitle,
.rowSearch,
.rowFilter {
  width: 100%;
  display: flex;
}

.rowTitle>div,
.rowSearch>div,
.rowFilter>div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 50px;
  padding-right: 20px;
}

.hidden {
  display: none !important;
}

.filters {
  display: flex;
  margin: 20px auto 0;
  width: 800px;
  max-width: 90vw;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 15px;
  /* height: 40px; */
}
.filter {
  display: flex;
  flex-wrap: wrap;
}
.filteredAmount {
display: flex;
align-items: center;
padding: 0 6px;
}
.filterInput {
  min-width: 200px;
  height: 40px;
  padding: 0 5px;
  border-radius: 6px;
  border: 1px solid #ccc !important;
  font-size: 15px;
  font-weight: 400 !important;
  margin-right: 10px;
}

.filters button {
  background: transparent;
  height: 40px;
  border: 1px solid #384f6d;
  color: #384f6d;
  cursor: pointer;
  margin-left: auto;
}