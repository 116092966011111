.headline-item {
  display: flex;
  margin: 20px auto 0;
  width: 800px;
  max-width: 90vw;
  justify-content: flex-start;
  padding: 10px 15px;
  position: relative;
  min-height: 40px;
  transition: .2s;
}
.headline-item.grouped {
  margin: 0 auto 0;
}



.headline-item::after{
  position: absolute;
  content: "";
  top: 10px;
  bottom:10px;
  left: 65px;
  width: 2px;
  background: #ccc;
  /* border-right: 1px solid #ccc; */
}
.headline-item.grouped::after {
  top: -10px;
  bottom:0;
}

.headline-item.grouped .date {
  color: transparent !important;
}

.headline-item.grouped .date span{
  color: transparent !important;
}

.headline-item {
  background: #f7f7f7;
  animation-name: updated;
  animation-duration: 1s;
}
.headline-item:nth-of-type(odd) {
  background: #fff;
  animation-name: updated-odd;
}

.headline-item:hover {
  background: rgb(156 195 213 / 30%) !important;
  transition: .4s;
}
.headline-item .date {
  min-width: 40px;
  padding-right: 5px;
  margin-right: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  /* box-shadow: 4px 0px 0 0 #000; */
  position: relative;
}

.headline-item .date .wd{
  display:flex;
  font-size: 1em;
  font-weight: 900;
  color:#384f6d;
}
.headline-item .date .dm{
  display:flex;
  font-size: 1em;
  letter-spacing: -1px;
}
.headline-item .date .y{
  font-size: .9em;
  font-weight:700;
  color:#ccc;
}
.headline-item .source {
  min-width: 40px;
  position: absolute;
  right: 0;
  top: 0;
}

.headline-item .link {
  min-width: 50px;
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.headline-item .link a,
.headline-item .link a:visited {
  line-height: 0 !important; 
  font-size: 0 !important;
  color: transparent !important; 
}

.headline-item .headline {
  padding-right: 50px;
  padding-left:10px;
}



.shl {
  /* background: #9cc3d5ff;
  border-radius: 4px; */
  /* color:#fff; */
  color: #00b4c8;
  background: transparent;
  font-weight: 900;
}

@keyframes updated {
  0%   {background-color: #fafafa;}
  25%  {background-color: rgb(156 195 213 / 60%);}
  100% {background-color: #fafafa;}
}
@keyframes updated-odd {
  0%   {background-color: #f1f1f1;}
  25%  {background-color: rgb(156 195 213 / 60%);}
  100% {background-color: #f1f1f1;}
}