.infoHeader {
    color: #384f6d;
    font-size: 1.2em;
    font-weight: bold
}

.headline-item.info .headline {
    padding: 0 5vw;
}

ul {
    padding: 0 25px;
}

ul li {
    text-decoration: none;
}

.suggestions > button {

    background-color: #f7f7f7;
    border: 0px;
    margin: 0 5px 5px 0;
    padding: 5px 10px;
    color: #384f6d;
    font-size: 1.1em;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.suggestions > button:hover {
    background: rgb(156 195 213 / 30%) !important;
    transition: .4s;
}

.welcome {
    display: flex;
    flex-wrap: wrap-reverse;
    margin: 0 auto 0;
    max-width: 1000px;
    width: 90vw;
    justify-content: center;
    padding: 10px 15px;
    position: relative;
}
.suggestions {
    min-width: 320px;
    flex: 1;
}
.info {
    background-color: #fff;
    padding: 15px 20px;
    min-width: 300px;
    flex: 1;
    color: #384f6d;
    font-size: 1.3em;
    margin: 0 10px 20px 0;
    /* flex: 1; */
}
.small {
    font-size: 0.8em;
}
.examples {
    background-color: #fff;
    border: 0px;
    margin: 0 5px 5px 0;
    padding: 5px 10px;
    color: #384f6d;
    font-size: 1.1em;
}